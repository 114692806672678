import React from 'react'
import Header from "../layout/header"
import Banner from "../pages/banner"
import Portfolio from './portfolio'
import Services from './service'
import Services2 from './service-2'
import About from './about'
import OurTeam from './our-team'
import PurchaseTheme from './purchase'
import PriceTable from './price-table'
import Testimonial from './testimonial'
import Clients from './clients'
import Footer from '../layout/footer'
import CopyRight from './copyright'

function Home() {
    return (
        <div>
         <Header/>
         <Banner/>
         <Services/>
         <Portfolio/>
         <Services2/>
         <About/>
         <OurTeam/>
         <PurchaseTheme/>
         <PriceTable/>
         <Testimonial/>
         <Clients/>
         <Footer/>
         <CopyRight/>
        </div>
    )
}
export default Home;