import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { backendApi } from "../apiService";

export default function Validate() {
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);
  const [dataFlag, setDataflag] = useState(false);
  const { secretKey, accessKey } = useParams();
  useEffect(() => {
    var req = {
      functionName: "userVerificationByEmailLink",
      access_key: accessKey,
      secret_key: secretKey,
    };
    backendApi(req).then((response) => {
      if (response.success == "1") {
        setflag(true);
        setDataflag(true);
      } else {
        setflag(false);
        setDataflag(true);
      }
    });
  }, []);

  return (
    <>
      <div className="xilliContent xiValidate">
        {flag ? (
          <div className="container">
            <div className="validation-form">
              <h1> Your Email Verification Is Success</h1>
              <h1> Please Click Here To Login..</h1>
              <a className="loginlink"
                onClick={() =>
                  navigate("/login")
                }
              >
                <span>Login</span>
              </a>
            </div>
          </div>
        ) : !flag && dataFlag ? (
          <div className="container">
            <div className="validation-form">
              <h1> Your Email Verification Is Failed </h1>
              <h1> Please Try Again...</h1>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="validation-form">
              <h1> Loading..................</h1>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
