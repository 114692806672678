import axios from "axios";
// export const awsUrl = "https://vcg2c7bmje.execute-api.ap-south-1.amazonaws.com/Prod";
export const awsUrl = "https://vcg2c7bmje.execute-api.ap-south-1.amazonaws.com/Prod";
const awsUrlS3 = "https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod";

export function backendApi(postData) {
	return axios
		.post(awsUrl, postData)
		.then((data) => data.data)
		.then((res) => res);
}
export const re = /^[a-zA-Z0-9+_.-]+@(?!gmail.com)(?!outlook.com)(?!rediffmail.com)(?!hotmail.com)(?!yahoo.com)(?!aol.com)(?!msn.com)[a-zA-Z0-9.-]+$/;
export const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/\S*)?$/;
export const phNum = /^\d{10}$/;
export function backendApiS3Credential(postData) {
	return axios
		.post(awsUrlS3, postData)
		.then((data) => data.data)
		.then((res) => res);
}
//http://103.121.27.62:3333/ public
// https://tagtree.xilligence.com:3333/
//http://192.168.0.112:8080/ private
//http://localhost:8080 dev
