import React, { useEffect } from 'react'
import { FaTachometerAlt,FaCogs,FaAndroid,FaShoppingCart,FaRegLightbulb } from "react-icons/fa";
import bugffyImg from "../images/bugffy.png"
import crmImg from "../images/crm.png"
import recruitmentImg from "../images/recruitment.png"
// import bugffyImg from "../images/bugffy.png"
// import bugffyImg from "../images/bugffy.png"
import AOS from 'aos';
// import 'aos/dist/aos.css';
function Services() {
    useEffect(() => {
        AOS.init();
      }, [])
    return (
        <div className="services">
        <div className='container'>
            <div class="col-md-12 services-sec">
                <span class="title-icon float-left">
                    <FaCogs />
                </span>
                <h2 class="title">Service We Provide <span class="title-desc">A Quality Experience Team with 4 years
                    experience</span></h2>
            </div>
            <div className="row g-4 pt-2 pb-0 py-md-5 row-cols-1 row-cols-lg-4">
                <div className="feature col" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="feature-icon mb-3">
                    <img src={bugffyImg} width={32} className='mt-1'></img>
                    </div>
                    <h3 className='services-head px-2'>BUGGIFY</h3>
                    <p className="service-para">Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>                   
                </div>
                <div className="feature col" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1200">
                    <div className="feature-icon mb-3">
                    <img src={crmImg} width={32} className='mt-1'></img>
                    </div>
                    <h3 className='services-head px-2'>CRM</h3>
                    <p classname="service-para">Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>                   
                </div>
                <div className="feature col" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1400">
                    <div className="feature-icon mb-3">
                    <img src={recruitmentImg} width={32} className='mt-1'></img>
                    </div>
                    <h3 className='services-head px-2'>RECRUITMENT</h3>
                    <p classname="service-para">Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>                   
                </div>
                <div className="feature col " data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1600">
                    <div className="feature-icon mb-3">
                    <FaRegLightbulb size={40}     color="#6A6A6A" />
                    </div>
                    <h3 className='services-head px-2'>DESIGN FOR STARTUPS</h3>
                    <p classname="service-para">Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>                   
                </div>
            </div>
        </div>
        </div>
    )
}
export default Services;