import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from "../layout/header"
import Footer from "../pages/copyright"
import Img from "../images/banner1.jpg"
function Form() {
    // State to manage the selected value
    const [selectedOption, setSelectedOption] = useState(null);
    const [isChecked, setChecked] = useState(false);

    // Options for the Select component
    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };
    // Handle change in selected value
    const handleChange = (selected) => {
        setSelectedOption(selected);
    };
    return (
        <div>
            <Header />
            <div className="banner-area">
                <img src={Img} alt="Img" className='img-fluid'/>
                <div class="parallax-overlay"></div>
                <div class="banner-title-content">
                    <div class="text-center">
                        <h2>Form</h2>
                        {/* <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item text-white" aria-current="page">Fill form</li>
                            </ol>
                        </nav> */}
                    </div>
                </div>
            </div>
            <div className='form-wrapper px-3 py-5'>
                <div class="col-md-12 col-lg-12">
                    {/* <h1 class="mb-3 main-head-form">Lorem Ipsum is simply 
                        <span className='green-text'> printing </span>
                    </h1> */}
                    <form class="needs-validation" novalidate="">
                        <div class="row g-3 mb-3">
                            <div class="col-sm-6">
                                <label for="firstName" class="form-label">First name</label>
                                <input type="text" class="form-control" placeholder='First Name *' />
                                <div class="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <label for="lastName" class="form-label">Last name</label>
                                <input type="text" class="form-control" placeholder='Last Name *' />
                                <div class="invalid-feedback">
                                    Valid last name is required.
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label for="firstName" class="form-label">Email Id</label>
                                <input type="text" class="form-control" placeholder='email id*' />
                                <div class="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label for="firstName" class="form-label">Comments</label>
                                <textarea type="text" class="form-control " rows="6" style={{ height: "80px" }} />
                                <div class="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            {/* <div class="form-check ms-3">
                            <input type="checkbox" class="form-check-input" id="same-address" />
                            <label class="form-check-label" for="same-address"></label>
                        </div> */}
                            <div class="custom-checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span class="checkmark"></span>
                                    Shipping address is the same as my billing address
                                </label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="d-flex">
                                <div class="form-check ">
                                    <input id="credit" name="paymentMethod" type="radio" class="form-check-input" />
                                    <label class="form-check-label" for="credit">Credit card</label>
                                </div>
                                <div class="form-check ms-3">
                                    <input id="credit" name="paymentMethod" type="radio" class="form-check-input" />
                                    <label class="form-check-label" for="credit">Credit card</label>
                                </div>
                                <div class="form-check ms-3">
                                    <input id="credit" name="paymentMethod" type="radio" class="form-check-input" />
                                    <label class="form-check-label" for="credit">Credit card</label>
                                </div>
                            </div>

                        </div>
                        <div className="row mb-3">
                            <Select className='react-select'
                                classNames={{
                                    control: (state) =>
                                        state.isFocused ? 'react-select-inner' : 'react-select-inner',
                                }}
                                value={selectedOption}
                                onChange={handleChange}
                                options={options}
                                isSearchable={true}
                            />
                        </div>

                        <div className='button-wrap d-md-flex justify-content-end mb-3'>
                            {/* <button className='btn btn-main btn-lg'>Submit</button> */}
                            <button className='btn btn-cancel me-2'>Cancel</button>
                            <button className='btn btn-main'>Submit</button>
                            {/* <button className='btn btn-main btn-sm ms-2'>Submit</button> */}
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Form;