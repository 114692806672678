import React ,{useEffect} from 'react'
import { FaUniversity } from "react-icons/fa";
import AOS from 'aos';
// import 'aos/dist/aos.css';
function PriceTable() {
	useEffect(() => {
        AOS.init();
      }, [])
    return (
        <div className="pricing">
            <div className='container'>
            <div className="col-md-12 services-sec">
                <span className="title-icon float-left">
                    <FaUniversity />
                </span>
                <h2 className="title">PRICING TABLE<span className="title-desc">We Love To Work With Passion</span></h2>

            </div>
            <div className="row">			
			<div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2000">
				<div className="plan text-center">
					<span className="plan-name">Basic <small>Monthly plan</small></span>
					<p className="plan-price"><sup className="currency">$</sup><strong>49</strong><sub>.99</sub></p>
					<ul className="list-unstyled">
						<li>100GB Monthly Bandwidth</li>
						<li>$100 Google AdWords</li>
						<li>100 Domain Hosting</li>
						<li>SSL Shopping Cart</li>
						<li>24/7 Live Support</li>
					</ul>
					<a className="btn btn-outline-primary" href="#.">Sign Up</a>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2100">
				<div className="plan text-center">
					<span className="plan-name">Standared <small>Monthly plan</small></span>
					<p className="plan-price"><sup className="currency">$</sup><strong>99</strong><sub>.99</sub></p>
					<ul className="list-unstyled">
						<li>100GB Monthly Bandwidth</li>
						<li>$100 Google AdWords</li>
						<li>100 Domain Hosting</li>
						<li>SSL Shopping Cart</li>
						<li>24/7 Live Support</li>
					</ul>
					<a className="btn btn-outline-primary" href="#.">Sign Up</a>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2200">
				<div className="plan text-center featured">
					<span className="plan-name">Professional <small>Monthly plan</small></span>
					<p className="plan-price"><sup className="currency">$</sup><strong>149</strong><sub>.99</sub></p>
					<ul className="list-unstyled">
						<li>100GB Monthly Bandwidth</li>
						<li>$100 Google AdWords</li>
						<li>100 Domain Hosting</li>
						<li>SSL Shopping Cart</li>
						<li>24/7 Live Support</li>
					</ul>
					<a className="btn btn-outline-primary" href="#.">Sign Up</a>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2300">
				<div className="plan text-center">
					<span className="plan-name">Premium <small>Monthly plan</small></span>
					<p className="plan-price"><sup className="currency">$</sup><strong>399</strong><sub>.99</sub></p>
					<ul className="list-unstyled">
						<li>100GB Monthly Bandwidth</li>
						<li>$100 Google AdWords</li>
						<li>100 Domain Hosting</li>
						<li>SSL Shopping Cart</li>
						<li>24/7 Live Support</li>
					</ul>
					<a className="btn btn-outline-primary" href="#.">Sign Up</a>
				</div>
			</div>
		</div>
            </div>
        </div>
    )
}
export default PriceTable;