import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from "../layout/header"
import Footer from "../pages/copyright"
import Img from "../images/banner1.jpg"
import { TiDelete } from "react-icons/ti";
import { MdEdit } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { CiFilter } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import Pagination from "react-js-pagination";
import BuggifyImg from "../images/buggify.PNG"

function Listing() {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <div>
            <Header />
            <div className="banner-area">
                <img src={Img} alt="Img" className='img-fluid' />
                <div class="parallax-overlay"></div>
                <div class="banner-title-content">
                    <div class="text-center">
                        <h2>User Management</h2>
                        {/* <nav aria-label="breadcrumb">
                                <ol class="breadcrumb justify-content-center">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item text-white" aria-current="page">Listing Table</li>
                                </ol>
                            </nav> */}
                    </div>
                </div>
            </div>
            <div class="mb-3 p-5 col-md-10 m-auto">
                <div class="table-responsive">
                    <div class="table-wrapper">
                        <div class="table-title">
                            <div class="row">
                                <div class="col-lg-5 col-md-5 d-flex align-items-center">
                                    {/* <h2 className='sub-head'>User <b >Management</b></h2> */}
                                    <input class="form-control me-2 w-auto" type="search" placeholder="Search" aria-label="Search"></input>
                                    <div className='table-left-btn'>
                                        <span><CiFilter size={20} className='me-1' /></span>
                                        Filter
                                    </div>
                                    <div className='table-left-btn'>
                                        <span><CiExport size={20} className='me-1' /></span>
                                        Export
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-7">
                                    <a class="add-user" title="Add a new user" onClick={handleShowModal}>
                                        <span><IoMdAddCircle size={20} className='me-1' /></span>
                                        <span >Add New User</span></a>
                                    {/* <a class="btn btn-secondary">
                                    <span>Export to Excel</span>
                                </a> */}
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Products</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>04/10/2013</td>
                                    <td>04/10/2013</td>
                                    <td><select class="form-select2" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></td>
                                    <td>Admin</td>
                                    <td className='d-flex align-items-center'><span class="status text-success">&bull;</span> <span>Active</span></td>
                                    <td>
                                        <a class="edit" title="Edit" >
                                            <MdEdit size={16} />
                                        </a>
                                        <a class="delete" title="Delete">
                                            <TiDelete size={23} className="text-danger" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>05/08/2014</td>
                                    <td>04/10/2013</td>
                                    <td>Publisher</td>
                                    <td>Admin</td>
                                    <td className='d-flex align-items-center'><span class="status text-success">&bull;</span> Active</td>
                                    <td>
                                        <a class="edit" title="Edit" >
                                            <MdEdit size={16} />
                                        </a>
                                        <a class="delete" title="Delete">
                                            <TiDelete size={23} className="text-danger" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>05/08/2014</td>
                                    <td>04/10/2013</td>
                                    <td>Publisher</td>
                                    <td>Admin</td>
                                    <td className='d-flex align-items-center'><span class="status text-success">&bull;</span> Active</td>
                                    <td>
                                        <a class="edit" title="Edit" >
                                            <MdEdit size={16} />
                                        </a>
                                        <a class="delete" title="Delete">
                                            <TiDelete size={23} className="text-danger" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>05/08/2014</td>
                                    <td>04/10/2013</td>
                                    <td>Publisher</td>
                                    <td>Admin</td>
                                    <td className='d-flex align-items-center'><span class="status text-success">&bull;</span> Active</td>
                                    <td>
                                        <a class="edit" title="Edit" >
                                            <MdEdit size={16} />
                                        </a>
                                        <a class="delete" title="Delete">
                                            <TiDelete size={23} className="text-danger" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>05/08/2014</td>
                                    <td>04/10/2013</td>
                                    <td>Publisher</td>
                                    <td>Admin</td>
                                    {/* <td className='d-flex align-items-center'><span class="status text-success">&bull;</span> Active</td> */}
                                    <td><div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
  
</div></td>
                                    <td>
                                        <a class="edit" title="Edit" >
                                            <MdEdit size={16} />
                                        </a>
                                        <a class="delete" title="Delete">
                                            <TiDelete size={23} className="text-danger" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="clearfix">
                            <Pagination
                                activePage={1}
                                itemsCountPerPage={10}
                                totalItemsCount={450}
                                pageRangeDisplayed={5}
                            />
                        </div>


                        <div
                            className={`modal fade-scale ${showModal ? 'in' : ''}`}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            style={{ display: showModal ? 'block' : 'none' }}
                        >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content p-3">
                                    <div className="modal-body">


                                        <div class="col-sm-12">
                                            <div className='modal-subhead'>
                                                
                                            <div className='text-center modal-main-head'>PRODUCTS</div>
                                            <p className='text-center'>Which store view do u like to explore?</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-8 col-sm-4 mb-1">
                                                    <div class="d-flex modal-boxes active">
                                                        <img src={BuggifyImg} alt="BuggifyImg" width="30" height="30" />
                                                        <p class="d-flex align-items-center mb-0 border-none ps-2">
                                                            <strong class="d-block text-gray-dark">BUGGIFY</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-sm-4 mb-1">
                                                <div class="d-flex modal-boxes">
                                                        <img src={BuggifyImg} alt="BuggifyImg" width="30" height="30" />
                                                        <p class="d-flex align-items-center mb-0 border-none ps-2">
                                                            <strong class="d-block text-gray-dark">BUGGIFY</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-sm-4 mb-1">
                                                <div class="d-flex modal-boxes">
                                                        <img src={BuggifyImg} alt="BuggifyImg" width="30" height="30" />
                                                        <p class="d-flex align-items-center mb-0 border-none ps-2">
                                                            <strong class="d-block text-gray-dark">BUGGIFY</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            onClick={handleCloseModal}
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn-save">
                                            Save changes
                                            <div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
                                        </button>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Listing;