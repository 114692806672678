import React, { useState ,useEffect} from 'react'
import FooterImg from '../images/footer-img.jpg'
import FooterImg1 from '../images/login_bg01.jpg'
import { FaRegEnvelope } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
function Footer() {
	
	const [showModal, setShowModal] = useState(false);
	const [showImgFlag, setShowImgFlag] = useState(false);
	const [showImg, setShowImg] = useState("");

	useEffect(() => {

      }, []);

	const handleShowModal = (img) => {
		console.log("img---", img);
		//document.getElementById("imggg").src = img;
		setShowModal(true);
		setTimeout(() => {
			setShowImgFlag(true)
			setShowImg(img)

		}, 500);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setShowImgFlag(false);
	};
	// document.addEventListener("DOMContentLoaded", function () {
	// 	var areaElements = document.querySelectorAll("area[rel^='prettyPhoto']");
	// 	areaElements.forEach(function (element) {
	// 	  element.addEventListener("click", function () {
	// 		// Assuming there's a prettyPhoto function defined somewhere
	// 		prettyPhoto();
	// 	  });
	// 	});
	  
	// 	var firstGalleryLinks = document.querySelectorAll(".gallery:first a[rel^='prettyPhoto']");
	// 	firstGalleryLinks.forEach(function (element) {
	// 	  element.addEventListener("click", function () {
	// 		prettyPhoto({
	// 		  animation_speed: 'normal',
	// 		  theme: 'light_square',
	// 		  slideshow: 3000,
	// 		  autoplay_slideshow: true
	// 		});
	// 	  });
	// 	});
	  
	// 	var otherGalleryLinks = document.querySelectorAll(".gallery:gt(0) a[rel^='prettyPhoto']");
	// 	otherGalleryLinks.forEach(function (element) {
	// 	  element.addEventListener("click", function () {
	// 		prettyPhoto({
	// 		  animation_speed: 'fast',
	// 		  slideshow: 10000,
	// 		  hideflash: true
	// 		});
	// 	  });
	// 	});
	  
	// 	var customContentFirstLink = document.querySelector("#custom_content a[rel^='prettyPhoto']:first");
	// 	customContentFirstLink.addEventListener("click", function () {
	// 	  prettyPhoto({
	// 		custom_markup: '<div id="map_canvas" style="width:260px; height:265px"></div>',
	// 		changepicturecallback: function () {
	// 		  initialize();
	// 		}
	// 	  });
	// 	});
	  
	// 	var customContentLastLink = document.querySelector("#custom_content a[rel^='prettyPhoto']:last");
	// 	customContentLastLink.addEventListener("click", function () {
	// 	  prettyPhoto({
	// 		custom_markup: '<div id="bsap_1259344" class="bsarocks bsap_d49a0984d0f377271ccbf01a33f2b6d6"></div><div id="bsap_1237859" class="bsarocks bsap_d49a0984d0f377271ccbf01a33f2b6d6" style="height:260px"></div><div id="bsap_1251710" class="bsarocks bsap_d49a0984d0f377271ccbf01a33f2b6d6"></div>',
	// 		changepicturecallback: function () {
	// 		  _bsap.exec();
	// 		}
	// 	  });
	// 	});
	//   });
	  
	return (
		<div className="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-4 col-lg-4 footer-widget mb-5 mb-lg-0">
						<h3 className="widget-title">Recent Posts</h3>
						<div className="latest-post-items media">
							<div className="latest-post-content media-body">
								<h4><a href="#">Bulgaria claims to find Europe's 'oldest town'</a></h4>
								<p className="post-meta">
									<span className="author">Posted by John Doe</span>
									<span className="post-meta-cat">in<a href="#"> Blog</a></span>
								</p>
							</div>
						</div>
						<div className="latest-post-items media">
							<div className="latest-post-content media-body">
								<h4><a href="#">Few Answers in Case of Murdered Law Professor</a></h4>
								<p className="post-meta">
									<span className="date"><i className="icon icon-calendar"></i> Mar 15, 2015</span>
									<span className="post-meta-comments"><i className="icon icon-bubbles4"></i> <a href="#">03</a></span>
								</p>
							</div>
						</div>

						<div className="latest-post-items media">
							<div className="latest-post-content media-body">
								<h4><a href="#">Over the year we have lots of experience in our field</a></h4>
								<p className="post-meta">
									<span className="date"><i className="icon icon-calendar"></i> Apr 17, 2015</span>
									<span className="post-meta-comments"><i className="icon icon-bubbles4"></i> <a href="#">14</a></span>
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-8 col-lg-4 footer-widget mb-5 mb-lg-0">
						<h3 className="widget-title">Flickr Photos</h3>
						
					

						<div className="img-gallery">
							<div className="img-container">
								<a className="thumb-holder"  onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg1)}>
									<img src={FooterImg1} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
								<a className="thumb-holder" onClick={() => handleShowModal(FooterImg)}>
									<img src={FooterImg} alt="" />
								</a>
							</div>
						</div>

					

						<div
							className={`modal fade-scale ${showModal ? 'in' : ''}`}
							tabIndex="-1"
							role="dialog"
							aria-labelledby="myModalLabel"
							style={{ display: showModal ? 'block' : 'none' }}
						>
							<div className="modal-dialog modal-dialog-centered" role="document" 
								key={showImgFlag}
							style={{
								maxWidth: showImgFlag ? 'auto' : '100px'
							  }}>
								<div className="modal-content">
									
									<div className="modal-body text-center">
										{showImgFlag ? 
											<img src={showImg} alt="FooterImg" id="imggg" className='img-fluid'/>
										:
											<LuLoader2 color='#000' className='loader'/>
										}
									</div>
									{showImgFlag &&
									<div className="modal-footer">
										<button
											type="button"
											className="close"
											onClick={handleCloseModal}
											aria-label="Close"
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-4 footer-widget footer-about-us mb-md-5 mb-0 mb-lg-0">
						<h3 className="widget-title">About Craft</h3>
						<p>We are a awward winning multinational company. We believe in quality and standard worldwide.</p>
						<h4>Address</h4>
						<p>1102 Saint Marys, Junction City, KS</p>
						<div className="row">
							<div className="col-12">
								<h4>Email:</h4>
								<p><a href="mailto:info@craftbd.com" className="text-white">info@craftbd.com</a></p>
							</div>
							<div className="col-12">
								<h4>Phone No.</h4>
								<p><a href="tel:+(785) 238-4131" className="text-white">+(785) 238-4131</a></p>
							</div>
						</div>
						<form action="#" role="form">
							<div className="input-group subscribe">
								<input type="email" className="form-control" placeholder="Email Address" required="" />
								<span className="input-group-addon">
									<button className="btn" type="submit"><FaRegEnvelope /></button>
								</span>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Footer;