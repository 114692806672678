import React, { useState, useEffect } from "react";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { backendApi, emailValidation, re } from "../apiService";
import { FaEye, FaEyeSlash } from "react-icons/fa";

$(document).ready(function () {
  "use strict";

  var usernameError = true,
    emailError = true,
    passwordError = true,
    passConfirm = true;

  // Detect browser for css purpose
  if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
    $(".form form label").addClass("fontSwitch");
  }

  // Label effect
  $("input").focus(function () {
    $(this).siblings("label").addClass("active");
  });

  // Form validation
  $("input").blur(function () {
    // label effect
    if ($(this).val().length > 0) {
      $(this).siblings("label").addClass("active");
    } else {
      $(this).siblings("label").removeClass("active");
    }
  });

  // form switch
  $("a.switch").click(function (e) {
    $(this).toggleClass("active");
    // e.preventDefault();

    if ($("a.switch").hasClass("active")) {
      $(this)
        .parents(".form-peice")
        .addClass("switched")
        .siblings(".form-peice")
        .removeClass("switched");
    } else {
      $(this)
        .parents(".form-peice")
        .removeClass("switched")
        .siblings(".form-peice")
        .addClass("switched");
    }
  });
});

function Login() {
  const [showSection, setShowSection] = useState("Login");
  const [company, setCompany] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [siteId, setSiteId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");

  const [isExistingSite, setIsExistingSite] = useState(false);

  const location = useLocation();

  // Set initial showSection based on the location
  // useEffect(() => {
  //   setShowSection(
  //     location.state && location.state.fromValidates ? "Login" : "Register"
  //   );
  // }, [location.state]);

  const CheckSite = () => {
    if (isSubmitting) {
      return;
    }
    if (email === "") {
      toast.warn("Please enter Email Id", { containerId: "D" });
      return false;
    } else if (!email.match(emailValidation)) {
      toast.warn("Kindly enter a valid email-Id", { containerId: "D" });
      return false;
    } else if (!re.test(email)) {
      toast.warn("Enter Valid Organization Email-Id please", {
        containerId: "D",
      });
      return false;
    }
    setIsSubmitting(true);
    var req = {
      functionName: "siteIsExistsChecking",
      email_id: email,
    };
    console.log("siteCheckReq", req);
    backendApi(req).then((response) => {
      console.log("respcheck site", response);
      if (response.success) {
        setIsSubmitting(false);
        if (response.dataSet.length>0 && response.sec_user.length>0) {
          toast.warn("This user is already registered.Please Login", { containerId: "D" });
          // setShowSection('Login');
        }else if (response.dataSet.length>0) {
            setIsExistingSite(true);
            setSiteId(response.dataSet[0].id)
        }else{
          toast.warn("Please register your organization first.", { containerId: "D" });
          setTimeout(() => {
            setShowSection('Register');
          }, 1500);
          
        }
      } else {
        setIsSubmitting(false);
        console.log("Error calling check site api");
      }
    });
  };

  const Register = (e) => {
    // e.preventDefault();
    if (isSubmitting) {
      return;
    }
    if (company === "") {
      toast.warn("Please enter Company Name", { containerId: "D" });
      return false;
    } else if (companyAddress === "") {
      toast.warn("Kindly enter Company Address", { containerId: "D" });
      return false;
    } else if (companyContact === "") {
      toast.warn("Enter enter Company Contact", {
        containerId: "D",
      });
      return false;
    } else if (companyEmail === "") {
      toast.warn("Enter enter Company Email", {
        containerId: "D",
      });
      return false;
    } else if (!companyEmail.match(emailValidation)) {
      toast.warn("Kindly enter a valid email-Id", { containerId: "D" });
      return false;
    } else if (!re.test(companyEmail)) {
      toast.warn("Enter Valid Organization Email-Id please", {
        containerId: "D",
      });
      return false;
    } else if (website === "") {
      toast.warn("Kindly enter Company's Website URL", { containerId: "D" });
      return false;
    }
    setIsSubmitting(true);
    var req = {
      functionName: "companyRegistration",
      company_name: company,
      address: companyAddress,
      contact_no: companyContact,
      mobile_no: companyMobile,
      email_id: companyEmail,
      web_site_url: website,
    };
    console.log("RegisterReq", req);
    backendApi(req).then((response) => {
      console.log("responseRegistration", response);
      if (response.success) {
        setIsSubmitting(false);
        setSiteId(response.dataSet[0].id);
        if (response.flag === "1") {
          toast.warn(
            "This Company is already Registered.Please Signup to continue.",
            { containerId: "D" }
          );
          setShowSection("Signup");
        } else if (response.flag === "0") {
          toast.success(
            "Company Registered Successfully.Please Signup to continue.",
            { containerId: "D" }
          );
          setShowSection("Signup");
        }
      } else {
        setIsSubmitting(false);
        console.log("Error calling register api");
      }
    });
  };

  const Signup = (e) => {
    if (isSubmitting) {
      return;
    }

    if (fullname === "") {
      toast.warn("Please enter Full Name", { containerId: "D" });
      return false;
    } else if (email === "") {
      toast.warn("Kindly enter Email Id", { containerId: "D" });
      return false;
    } else if (!email.match(emailValidation)) {
      toast.warn("Kindly enter a valid email-Id", { containerId: "D" });
      return false;
    } else if (!re.test(email)) {
      toast.warn("Enter Valid Organization Email-Id please", {
        containerId: "D",
      });
      return false;
    } else if (mobile === "") {
      toast.warn("Kindly enter Email Id", { containerId: "D" });
      return false;
    } else if (password === "") {
      toast.warn("Kindly enter a password", { containerId: "D" });
      return false;
    } else if (password.length < 8) {
      toast.warn("Password must be at least 8 characters long");
      return false;
    } else if (confirmPassword === "") {
      toast.warn("Kindly enter a password", { containerId: "D" });
      return false;
    } else if (password !== confirmPassword) {
      toast.warn("Passwords do not match !", { containerId: "D" });
      return false;
    }

    const names = fullname.split(" ");
    if (names.length >= 2) {
      setIsSubmitting(true);
      console.log("siteId", siteId);
      var req = {
        functionName: "userRegistration",
        site_id: siteId,
        email_id: email,
        full_name: fullname,
        password: password,
        mobile_no: mobile,
      };
      console.log("signupreq", req);
      backendApi(req).then((response) => {
        console.log("signupresp", response);
        if (response.success == 1) {
          setIsSubmitting(false);
          if (response.successMessage === "User Already Exists") {
            toast.warn("User Already Exists.Please Login.", {
              containerId: "D",
            });
          } else {
            toast.success(
              "User registered succesfully.Please check your email for verification.",
              { containerId: "D" }
            );
          }
          setTimeout(() => {
            setShowSection("Login");
          }, 2000);
        } else {
          setIsSubmitting(false);
          toast.warn("Something went wrong.", { containerId: "D" });
          return false;
        }
      });
    } else {
      toast.warn(
        "Invalid name format. Please provide both first name and last name.",
        { containerId: "D" }
      );
      setIsSubmitting(false);
      return false;
    }
  };

  const UserLogin = () => {
    if (isSubmitting) {
      return;
    }

    if (username === "") {
      toast.warn("Please enter Email Id", { containerId: "D" });
      return false;
    } else if (pwd === "") {
      toast.warn("Please enter password", { containerId: "D" });
      return false;
    }
    setIsSubmitting(true);

    var req = {
      functionName: "login_auth",
      email_id: username,
      password: pwd,
    };
    console.log("login", req);
    backendApi(req).then((response) => {
      console.log("loginres", response);
      if (response.success == 1) {
        setIsSubmitting(false);
        localStorage.setItem("site_id",response.userDetails.site_id);
        localStorage.setItem("userId",response.userDetails.id);
        localStorage.setItem("emp_code",response.userDetails.emp_code);
        localStorage.setItem("emailId",response.userDetails.email_id);
        window.location.href = "/listing";
      } else {
        setIsSubmitting(false);
        toast.warn("Wrong email id or password.", { containerId: "D" });
        return false;
      }
    });
  };

  return (
    <>
      <div class="container">
        <ToastContainer
          enableMultiContainer
          containerId={"D"}
          position="bottom-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <section id="formHolder">
          <div class="row">
            <div class="col-sm-6 brand">
              <a href="#" class="logo">
                LOGO <span>.</span>
              </a>
              <div class="heading">
                <h2>seffo</h2>
                <p>Your Right Choice</p>
              </div>
              <div class="success-msg">
                <p>Great! You are one of our members now</p>
                <a href="#" class="profile">
                  Your Profile
                </a>
              </div>
            </div>
            {showSection === "Signup" ? (
              <div class="col-sm-6 form-login">
                <div class="signup form-peice">
                  <form class="signup-form" action="#" method="post">
                    {isExistingSite ? (
                      <>
                        <div class="form-group">
                          <label for="email">Email Address</label>
                          <input
                            type="email"
                            name="emailAddress"
                            value={email}
                            readOnly
                            // onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            class="email"
                          />
                          <span class="error"></span>
                        </div>
                        <div class="form-group">
                          <label for="name">Full Name</label>
                          <input
                            type="text"
                            name="username"
                            value={fullname}
                            onChange={(e) => setFullname(e.target.value)}
                            id="name"
                            class="name"
                          />
                          <span class="error"></span>
                        </div>
                        <div class="form-group">
                          <label for="phone">Mobile Number</label>
                          <input
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            type="text"
                            name="phone"
                            id="phone"
                          />
                        </div>

                        <div class="form-group">
                          <label for="password">Password</label>
                          <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            class="pass"
                          />
                          <span class="error"></span>
                        </div>

                        <div class="form-group">
                          <label for="passwordCon">Confirm Password</label>
                          <input
                            type="password"
                            name="passwordCon"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            id="passwordCon"
                            class="passConfirm"
                          />
                          <span class="error"></span>
                        </div>

                        <div class="CTA">
                          <Link>
                            <input
                              type="submit"
                              value="Signup Now"
                              id="submit"
                              onClick={isSubmitting ? null : Signup}
                              disabled={isSubmitting}
                            />
                          </Link>
                          <a
                            href="#"
                            class="switch"
                            onClick={() => setShowSection("Login")}
                          >
                            I have an account
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="form-group">
                          <label for="email">Email Address</label>
                          <input
                            type="email"
                            name="emailAddress"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            class="email"
                          />
                          <span class="error"></span>
                        </div>
                        <div class="CTA">
                          <Link>
                            <input
                              type="submit"
                              value="Signup Now"
                              id="submit"
                              onClick={isSubmitting ? null : CheckSite}
                              disabled={isSubmitting}
                            />
                          </Link>
                          <a
                            href="#"
                            class="switch"
                            onClick={() => setShowSection("Login")}
                          >
                            I have an account
                          </a>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            ) : showSection === "Register" ? (
              <div class="col-sm-6 form-login">
                {/* <div class="login form-peice">
                  <form class="login-form" action="#" method="post">
                    <div class="form-group">
                      <label for="loginemail">Email Address</label>
                      <input type="email" name="loginemail" id="loginemail" />
                    </div>

                    <div class="form-group">
                      <label for="loginPassword">Password</label>
                      <input
                        type="password"
                        name="loginPassword"
                        id="loginPassword"
                      />
                    </div>

                    <div class="CTA">
                      <input type="submit" value="Login" />
                      <a href="#" class="switch">
                        I'm New
                      </a>
                    </div>
                  </form>
                </div> */}
                <div class="signup form-peice">
                  <form class="signup-form" action="#" method="post">
                    <div class="form-group">
                      <label for="name">Company Name</label>
                      <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        name="companyname"
                        id="companyname"
                        class="companyname"
                      />
                      <span class="error"></span>
                    </div>
                    <div class="form-group">
                      <label for="name">Company Address</label>
                      <input
                        type="text"
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        name="companyaddress"
                        id="companyaddress"
                        class="companyaddress"
                      />
                      <span class="error"></span>
                    </div>
                    <div class="form-group">
                      <label for="contact">Contact Number</label>
                      <input
                        value={companyContact}
                        onChange={(e) => setCompanyContact(e.target.value)}
                        type="text"
                        name="contact"
                        id="contact"
                      />
                    </div>
                    <div class="form-group">
                      <label for="phone">
                        Mobile Number - <small>Optional</small>
                      </label>
                      <input
                        value={companyMobile}
                        onChange={(e) => setCompanyMobile(e.target.value)}
                        type="text"
                        name="phone"
                        id="phone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email Address</label>
                      <input
                        type="email"
                        value={companyEmail}
                        onChange={(e) => setCompanyEmail(e.target.value)}
                        name="emailAdress"
                        id="email"
                        class="email"
                      />
                      <span class="error"></span>
                    </div>

                    <div class="form-group">
                      <label for="website">Website Url</label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        class="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                      <span class="error"></span>
                    </div>
                    <div class="CTA">
                      <Link>
                        <input
                          type="submit"
                          value="Register"
                          id="submit"
                          onClick={isSubmitting ? null : Register}
                          disabled={isSubmitting}
                        />
                      </Link>
                      <a
                        href="#"
                        onClick={() => setShowSection("Login")}
                        class="switch"
                      >
                        I have an account
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            ) : showSection === "Login" ? (
              <div class="col-sm-6 form-login">
                <div class="login form-peice">
                  <form class="login-form" action="#" method="post">
                    <div class="form-group">
                      <label for="loginemail">Email Address</label>
                      <input
                        type="email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        name="loginemail"
                        id="loginemail"
                      />
                    </div>

                    <div class="form-group">
                      <label for="loginPassword">Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            UserLogin();
                          }
                        }}
                        name="loginPassword"
                        id="loginPassword"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}
                      />
                      <span className="showPswd">
                        {showPassword && (
                          <FaEye onClick={() => setShowPassword(false)} />
                        )}
                        {!showPassword && (
                          <FaEyeSlash onClick={() => setShowPassword(true)} />
                        )}
                      </span>
                    </div>

                    <div class="CTA">
                      <input
                        type="submit"
                        onClick={isSubmitting ? null : UserLogin}
                        disabled={isSubmitting}
                        value="Login"
                      />
                      <a
                        href="#"
                        class="switch"
                        onClick={() => setShowSection("Signup")}
                      >
                        I'm New
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </>
  );
}
export default Login;
