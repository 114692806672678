import React,{useEffect} from 'react'
import AOS from 'aos';
// import 'aos/dist/aos.css';
import { FaFacebookF,FaTwitter,FaGooglePlusG,FaDribbble,FaLinkedinIn,FaPinterestP,FaSkype } from "react-icons/fa";
function CopyRight() {
	useEffect(() => {
        AOS.init();
      }, [])
	const currentYear = new Date().getFullYear();
    return (
        <section id="copyright" class="copyright angle">
	  <div class="container">
	    <div class="row">
	      <div class="col-md-12 text-center">
	        <ul class="footer-social unstyled">
	          <li>
	            <a title="Twitter" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaTwitter/></span>
	            </a>
	            <a title="Facebook" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaFacebookF/></span>
	            </a>
	            <a title="Google+" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaGooglePlusG/></span>
	            </a>
	            <a title="linkedin" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaLinkedinIn/></span>
	            </a>
	            <a title="Pinterest" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaPinterestP/></span>
	            </a>
	            <a title="Skype" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaSkype/></span>
	            </a>
	            <a title="Dribble" href="#" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500">
	              <span class="icon-pentagon"><FaDribbble/></span>
	            </a>
	          </li>
	        </ul>
	      </div>
	    </div>
	    <div class="row">
	      <div class="col-md-12 text-center">
	        <div class="copyright-info">
	          © Copyright {currentYear} seffo. <span>Designed by <a href="/">seffo</a></span>
	        </div>
	      </div>
	    </div>
	    <div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top affix position-fixed">
	      <button class="btn btn btn-outline-white" title="Back to Top"><i class="fa fa-angle-double-up"></i></button>
	    </div>
	  </div>
	</section>
    )
}
export default CopyRight;