import React from 'react';
import { FaCogs } from "react-icons/fa";
import AbtImg from "../images/about.jpg"
function About() {
    const divStyle = {
        height: '650px',
        background: `url(${AbtImg}) 50% 50% / cover no-repeat`,
      };
    return (
        <div className="about-us">
            <div className="row mx-0">
                <div className="col-md-6 px-0 about-img-col" style={divStyle}>
                </div>
                <div class="col-lg-6 ts-padding img-block-right">
                    <div class="img-block-head text-center">
                        <h2>Know More About Our Company</h2>
                        <h3 className='text-dark'>Why Choose Us</h3>
                        <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Proin gravida nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed lorem quis bibendum auctor. </p>
                    </div>
                    <div class="gap-30"></div>
                    <div class="image-block-content">
                        <span class="feature-icon float-left">
                        <FaCogs size={30}     color="#fff" />
                        </span>
                        <div class="feature-content">
                            <h3>Tons of Features</h3>
                            <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut</p>
                        </div>
                    </div>
                    <div class="image-block-content">
                        <span class="feature-icon float-left">
                        <FaCogs size={30}     color="#fff" />
                        </span>
                        <div class="feature-content">
                            <h3>PowerPack Theme</h3>
                            <p>Proin gravida nibh vel velit auctor Aenean sollicitudin adipisicing</p>
                        </div>
                    </div>
                    <div class="image-block-content">
                        <span class="feature-icon float-left">
                        <FaCogs size={30}     color="#fff" />
                        </span>
                        <div class="feature-content">
                            <h3>Day Night Support</h3>
                            <p>Simply dummy text and typesettings industry has been the industry</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;