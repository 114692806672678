import React,{useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PortfolioImg from "../images/portfolio2.jpg"
import bugiffyPortfolio from "../images/bugiffy-portfolio.jpg"
import crmPortfolio from "../images/crm-portfolio.jpg"
import hrmsPortfolio from "../images/hrms-portfolio.jpg"
import tagtreePortfolio from "../images/tagtree-portfolio.jpg"
import recruitmentPortfolio from "../images/recruitment-portfolio.jpg"
import gigavibePortfolio from "../images/gigavibe-portfolio.jpg"
import expencePortfolio from "../images/expence-portfolio.jpg"
import { LuLoader2 } from "react-icons/lu";
import { FaCog,FaUser,FaBriefcase,FaTrophy } from "react-icons/fa";
import { FaBuildingColumns } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { FaLink } from "react-icons/fa6";
function Portfolio() {
    const [showModal, setShowModal] = useState(false);
	const [showImgFlag, setShowImgFlag] = useState(false);
    const [showImg, setShowImg] = useState("");
    const handleShowModal = (img) => {
		console.log("img---", img);
		//document.getElementById("imggg").src = img;
		setShowModal(true);
		setTimeout(() => {
			setShowImgFlag(true)
            setShowImg(img)
		}, 500);
	};
    const handleCloseModal = () => {
		setShowModal(false);
		setShowImgFlag(false);
	};
    return (
        <div className='portfolio'>
            <div className="">
                <div className="container">
                <div class="row">
                    <div class="col-lg-5 heading text-lg-left text-center mb-5">
                        <span class="title-icon classic">  <FaCog /></span>
                        <h2 class="title classic">Our Awesome Work</h2>
                    </div>
                    <div class="col-lg-7 mb-5">
                        <div className="float-right">
                        <Tabs>
                            <TabList>
                                <Tab>All</Tab>
                                <Tab>Web Design</Tab>
                                <Tab>Development</Tab>
                                <Tab>Joomla</Tab>
                                <Tab>Wordpress</Tab>

                            </TabList>
                        </Tabs>
                        </div>
                       

                    </div>
                </div>
                </div>
                <div className="row mx-0">
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={bugiffyPortfolio} alt="Img" className='img-fluid' />
                        <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Buggify</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a  className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={tagtreePortfolio} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Tagtree</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={crmPortfolio} alt="Img" className='img-fluid' />
                        <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>CRM</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={hrmsPortfolio} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>HRMS</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={recruitmentPortfolio} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Recruitment</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a  className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={gigavibePortfolio} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Gigavibe</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={expencePortfolio} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Expense Tracker</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a  className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-md-3 px-0 portfolio-img">
                        <img src={PortfolioImg} alt="Img" className='img-fluid' />
                         <div className="overlay-portfolio">
                            <h3 className='overlay-portfolio-head'>Coming Soon</h3>
                            <div className='overlay-links'>
                            <a href="" className='links-control pe-3'> <FaLink size={20}/></a>
                            <a className='links-control' onClick={() => handleShowModal(PortfolioImg)}> <IoSearch  size={25}/></a>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="row mx-0 facts-wrapper">
                    <div className="col-md-3 px-0">
                        <div class="facts one col-md-3 col-sm-6">
                            <span class="facts-icon">
                              <FaUser size={30}/>
                                </span>
                            <div class="facts-num">
                                <span class="counter">1200</span>
                            </div>
                            <h3>Clients</h3>
                        </div>
                       
                    </div>
                    <div className="col-md-3 px-0">
                        <div class="facts two col-md-3 col-sm-6">
                            <span class="facts-icon">
                            <FaBuildingColumns size={30}/>
                                </span>
                            <div class="facts-num">
                                <span class="counter">1200</span>
                            </div>
                            <h3>Clients</h3>
                        </div>
                       
                    </div>
                    <div className="col-md-3 px-0">
                        <div class="facts three col-md-3 col-sm-6">
                            <span class="facts-icon">
                            <FaBriefcase size={30}/>
                                </span>
                            <div class="facts-num">
                                <span class="counter">1200</span>
                            </div>
                            <h3>Clients</h3>
                        </div>
                       
                    </div>
                    <div className="col-md-3 px-0">
                        <div class="facts four col-md-3 col-sm-6">
                            <span class="facts-icon">
                            <FaTrophy size={30}/>
                                </span>
                            <div class="facts-num">
                                <span class="counter">1200</span>
                            </div>
                            <h3>Clients</h3>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div
							className={`modal fade-scale ${showModal ? 'in' : ''}`}
							tabIndex="-1"
							role="dialog"
							aria-labelledby="myModalLabel"
							style={{ display: showModal ? 'block' : 'none' }}
						>
							<div className="modal-dialog modal-dialog-centered" role="document" 
								key={showImgFlag}
							style={{
								maxWidth: showImgFlag ? 'auto' : '100px'
							  }}>
								<div className="modal-content">
									
									<div className="modal-body text-center">
										{showImgFlag ? 
											<img src={showImg} alt="FooterImg" id="imggg" className='img-fluid'/>
										:
											<LuLoader2 color='#000' className='loader'/>
										}
									</div>
									{showImgFlag &&
									<div className="modal-footer">
										<button
											type="button"
											className="close"
											onClick={handleCloseModal}
											aria-label="Close"
										>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									}
								</div>
							</div>
						</div>
        </div>
        // <div className='portfolio'>
        //     <div className='container'>
        //         <div className='row'>
        //             <div className="col-lg-5 heading">
        //             <span className="title-icon classic">
        //                 <FaSuitcase/>
        //             </span>
        //             <h2 className="title classic">Our Awesome Work</h2>
		// 	</div>
        //     <div className="col-lg-7">
        //         <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        //         <li className="nav-item">
        //             <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Home</a>
        //         </li>
        //         <li className="nav-item">
        //             <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a>
        //         </li>
        //         <li className="nav-item">
        //             <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</a>
        //         </li>
        //         <li className="nav-item">
        //             <a className="nav-link" id="pills-joomla-tab" data-toggle="pill" href="#pills-joomla" role="tab" aria-controls="pills-joomla" aria-selected="false">Contact</a>
        //         </li>
        //         <li className="nav-item">
        //             <a className="nav-link" id="pills-wordpress-tab" data-toggle="pill" href="#pills-wordpress" role="tab" aria-controls="pills-wordpress" aria-selected="false">Contact</a>
        //         </li>
        //         </ul>
		// 	</div>
        //         </div>
        //     </div>
        //     <div>
        //     <div className="tab-content" id="pills-tabContent">
        //         <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">A</div>
        //         <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">b</div>
        //         <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">c</div>
        //         <div className="tab-pane fade" id="pills-joomla" role="tabpanel" aria-labelledby="pills-joomla-tab">d</div>
        //         <div className="tab-pane fade" id="pills-wordpress" role="tabpanel" aria-labelledby="pills-wordpress-tab">e</div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default Portfolio;