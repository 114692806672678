import React from 'react'
function PurchaseTheme() {
    return (
        <div className="parallax parallax1">
	<div className="parallax-overlay"></div>
	<div className="container position-relative">
		<div className="row">
			<div className="col-md-12 text-center">
				<h2>Are You Ready to Buy This Template!</h2>
				<h3>Just Click Button and Use Your Own</h3>
				<p>
					<a href="#" className="btn btn-outline-white me-2">Purchase Now</a>
					<a href="#" className="btn btn-outline-primary text-white">Get In Touch</a>
				</p>
			</div>
		</div>
	</div>
</div>
    )
}
export default PurchaseTheme;