import React from 'react'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import UserImg from '../images/user.jpg'
import { FaQuoteLeft,FaQuoteRight } from "react-icons/fa";
function Testimonial() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false
  };
    return (
        <div class="testimonial parallax parallax2">
	<div class="parallax-overlay"></div>
	<div class="container">
		<div class="row">
    <Slider {...settings}>
      <div>
					<div class="testimonial-thumb">
						<img src={UserImg} alt="testimonial" className='d-block mx-auto'/>
					</div>
					<div class="testimonial-content text-center">
						<p class="testimonial-text">
						<FaQuoteLeft style={{color:'#49a2e7'}} className="me-2"/>	Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still
							in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
							purpose. Lorem Ipsum is that it as opposed to using.<FaQuoteRight style={{color:'#49a2e7'}} className="ms-2"/>
						</p>
						<h3 class="name">Taline Lucine<span>Sales Manager</span></h3>
					</div>
      </div>
      <div>
      <div class="testimonial-thumb">
						<img src={UserImg} alt="testimonial" className='d-block mx-auto'/>
					</div>
      <div class="testimonial-content  text-center">
						<p class="testimonial-text">
            <FaQuoteLeft style={{color:'#49a2e7'}} className="me-2"/>	Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still
							in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
							purpose. Lorem Ipsum is that it as opposed to using.<FaQuoteRight style={{color:'#49a2e7'}} className="ms-2"/>
						</p>
						<h3 class="name">Taline Lucine<span>Sales Manager</span></h3>
					</div>
      </div>
      <div>
      <div class="testimonial-thumb">
						<img src={UserImg} alt="testimonial" className='d-block mx-auto'/>
					</div>
      <div class="testimonial-content">
						<p class="testimonial-text">
            <FaQuoteLeft style={{color:'#49a2e7'}} className="me-2"/>	Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still
							in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
							purpose. Lorem Ipsum is that it as opposed to using.<FaQuoteRight style={{color:'#49a2e7'}} className="ms-2"/>
						</p>
						<h3 class="name">Narek Bedros<span>Sr. Manager</span></h3>
					</div>
        </div>
    </Slider>
        </div>
	</div>
</div>
    )
}
export default Testimonial;