import React,{useEffect} from 'react';
import AOS from 'aos';
// import 'aos/dist/aos.css';
import { FaRegHeart,FaCogs,FaCodepen } from "react-icons/fa";
function Services2() {
  useEffect(() => {
    AOS.init();
  }, [])
    return (
        <div className="services2">
        <div className='container'>
        <div class="row g-4  row-cols-1 row-cols-lg-3">
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="600">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaRegHeart size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">CLEAN & MODERN DESIGN</h2>
          <p>Bras urna felis accumsan at ultrde cesid posuere masa socis nautoque penat</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="800">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCodepen size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">USEFUL SHORTCODES</h2>
          <p>High Life narwhal, banh mi PBR single-origin coffee Odd Future actually aliqua</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="1000">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">PARALLAX SECTION</h2>
          <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="1200">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">MULTIPURPOSE CONCEPT</h2>
          <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="1400">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">RESPONSIVE LAYOUT</h2>
          <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="1600">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">LIGHT WIGHT PERFORMANCE</h2>
          <p>High Life narwhal, banh mi PBR single-origin coffee Odd Future actually aliqua</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="1800">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">FREE LIFETIME UPDATES</h2>
          <p>Bras urna felis accumsan at ultrde cesid posuere masa socis nautoque penat</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-duration="2000">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">ENDLESS POSSIBILITES</h2>
          <p>High Life narwhal, banh mi PBR single-origin coffee Odd Future actually aliqua</p>
         
        </div>
      </div>
      <div class="col d-flex align-items-start" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="900">
        <div class="icon-square bg-light text-dark flex-shrink-0 me-4">
        <FaCogs size={25}     color="#81c83c" />
        </div>
        <div>
          <h2 className="services-title">24/7 LIVE SUPPORT</h2>
          <p>Consectetur adipisicing elit sed do eiusmod tempor incididunt ut</p>
         
        </div>
      </div>
    </div>
        </div>
        </div>
    )
}
export default Services2;