import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import '../css/bootstrap.min.css'
import '../css/style.css'
import '../css/responsive.css'
import Logo from "../images/logo.png"

function Header() {
    const [menuOpen, setmenuOpenn] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const menuToggle = () => {
        setmenuOpenn(!menuOpen)
        document.body.classList.toggle('overflow-hidden')
    }
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return (
        <div className='main-wrapper'>
            <div className={`header fixed-top ${scrollPosition > 100 ? 'fixed-div' : ''}`}>
            <div className='container'>
                <header className="navbar navbar-expand-lg navbar-light">
                    <Link to="/" className="navbar-brand text-decoration-none py-0">
                        {/* <img src={Logo} alt="Logo" /> */}
                        <span className='logo-seffo'>SEFFO</span>
                    </Link>
                    <button className="navbar-toggler" type="button" onClick={() => menuToggle()}>
                        <div className={`bar-icon ${menuOpen ? 'menu-change' : ''}`}>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </div>
                    </button>
                    <div className={`collapse navbar-collapse nav-bar-collapse menu-collapse ${menuOpen ? 'menuOpen' : ''}`}>

                        <ul className="nav nav-pills ms-auto ">
                            <li className="nav-item dropdown-parent">
                                <Link to="/" className="nav-link">Agency</Link>
                                <div className="dropdown-submenu">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">Homepage 1</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu2</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu3</Link>
                                    </li>
                                </div>
                            </li>
                            <li className="nav-item dropdown-parent">
                                <Link to="/" className="nav-link">Client</Link>
                                <div className="dropdown-submenu">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu1</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu2</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu3</Link>
                                    </li>
                                </div>
                            </li>
                            <li className="nav-item dropdown-parent">
                                <Link to="/" className="nav-link">Jobs</Link>
                                <div className="dropdown-submenu">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu1</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu2</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">submenu3</Link>
                                    </li>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Industries</Link>
                                
                            </li>
                            <li className="nav-item">
                                <Link to="/login" className="nav-link login-btn">Login</Link>
                                
                            </li>
                        </ul>
                    </div>
                </header>
            </div>
            </div>
        </div>
    )
}
export default Header