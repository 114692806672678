import React,{useEffect} from 'react'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import client from '../images/client2.png'
import AOS from 'aos';
// import 'aos/dist/aos.css';
function Clients() {
    useEffect(() => {
        AOS.init();
      }, [])
    var settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        autoplay: true,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }
              },
              {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
        ]
    };
    return (
        <div className="clients" data-aos="fade-right" data-aos-delay="1000">
            <div className="container">
                <div className="row">
                    <Slider {...settings}>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                        <div>
                            <figure className="m-0 item client_logo">
                                <a href="#">
                                    <img src={client} alt="client" />
                                </a>
                            </figure>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default Clients;