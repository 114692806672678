import React,{useEffect} from 'react'
import { AiOutlineMessage } from "react-icons/ai";
import TeamImg01 from '../images/team1.jpg'
import TeamImg02 from '../images/team2.jpg'
import TeamImg03 from '../images/team3.jpg'
import TeamImg04 from '../images/team4.jpg'
import { FaFacebookF,FaTwitter,FaGooglePlusG,FaDribbble,FaLinkedinIn } from "react-icons/fa";
import AOS from 'aos';
// import 'aos/dist/aos.css';
function OurTeam() {
	useEffect(() => {
        AOS.init();
      }, [])
    return (
        <div className='our-team'>
                  <div className='container'>
            <div className="col-md-12 services-sec">
                <span className="title-icon float-left">
                    <AiOutlineMessage />
                </span>
                <h2 className="title">MEET WITH OUR TEAM <span className="title-desc">A Quality Experience Team With 4 Years Experience</span></h2>

            </div>
            <div className="row text-center">
			<div className="col-md-6 col-lg-3" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1200">
				<div className="team">
					<div className="img-hexagon">
						<img src={TeamImg01} alt=""/>
						<span className="img-top"></span>
						<span className="img-bottom"></span>
					</div>
					<div className="team-content">
						<h3>Vosgi Varduhi</h3>
						<p>Web Designer</p>
						<div className="team-social">
							<a className="fb" href="#"><FaFacebookF/></a>
							<a className="twt" href="#"><FaTwitter/></a>
							<a className="gplus" href="#"><FaGooglePlusG/></a>
							<a className="linkdin" href="#"><FaLinkedinIn/></a>
							<a className="dribble" href="#"><FaDribbble/></a>
						</div>
					</div>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-right"  data-aos-easing="linear" data-aos-duration="1200">
				<div className="team">
					<div className="img-hexagon">
						<img src={TeamImg02} alt=""/>
						<span className="img-top"></span>
						<span className="img-bottom"></span>
					</div>
					<div className="team-content">
						<h3>ROBERT ALESKA</h3>
						<p>Web Designer</p>
						<div className="team-social">
							<a className="fb" href="#"><FaFacebookF/></a>
							<a className="twt" href="#"><FaTwitter/></a>
							<a className="gplus" href="#"><FaGooglePlusG/></a>
							<a className="linkdin" href="#"><FaLinkedinIn/></a>
							<a className="dribble" href="#"><FaDribbble/></a>
						</div>
					</div>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-left"  data-aos-easing="linear" data-aos-duration="1200">
				<div className="team">
					<div className="img-hexagon">
						<img src={TeamImg03} alt=""/>
						<span className="img-top"></span>
						<span className="img-bottom"></span>
					</div>
					<div className="team-content">
						<h3>TALINE VOSKI</h3>
						<p>Web Designer</p>
						<div className="team-social">
							<a className="fb" href="#"><FaFacebookF/></a>
							<a className="twt" href="#"><FaTwitter/></a>
							<a className="gplus" href="#"><FaGooglePlusG/></a>
							<a className="linkdin" href="#"><FaLinkedinIn/></a>
							<a className="dribble" href="#"><FaDribbble/></a>
						</div>
					</div>
				</div>
			</div>
            <div className="col-md-6 col-lg-3" data-aos="fade-left"  data-aos-easing="linear" data-aos-duration="1200">
				<div className="team">
					<div className="img-hexagon">
						<img src={TeamImg04} alt=""/>
						<span className="img-top"></span>
						<span className="img-bottom"></span>
					</div>
					<div className="team-content">
						<h3>ALBAN SPENCER</h3>
						<p>Web Designer</p>
						<div className="team-social">
							<a className="fb" href="#"><FaFacebookF/></a>
							<a className="twt" href="#"><FaTwitter/></a>
							<a className="gplus" href="#"><FaGooglePlusG/></a>
							<a className="linkdin" href="#"><FaLinkedinIn/></a>
							<a className="dribble" href="#"><FaDribbble/></a>
						</div>
					</div>
				</div>
			</div>
		</div>
            </div>
        </div>
    )
}
export default OurTeam;