import React ,{useEffect}from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCogs } from "react-icons/fa";
import 'animate.css';
import bugffyImg from "../images/bugffy.png"
import crmImg from "../images/crm.png"
import AOS from 'aos';
// import 'aos/dist/aos.css';
function Banner() {
    useEffect(() => {
        AOS.init();
      }, [])
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: (i) => (
            <div className='react-slick-dots'>
              <img src={bugffyImg} width={35} className='mt-1'></img>
              <p className='mb-1'>Buggify</p>
            </div>
          ),
       
    };
   
  
    return (
        <div className="" style={{marginTop:"-5%"}}>
            <Slider {...settings}>
                <div className='banner'>
                    <div className="overlay">                        
                        <main className="banner-form">
                            <h1 className="main-head" data-aos="fade-up" data-aos-easing="linear" data-aos-delay="400">Cover your page.</h1>
                            <p className="sub-text " data-aos="fade-up" data-aos-easing="linear" data-aos-delay="600">Cover is a one-page template for building simple and beautiful home pages.</p>
                            <div className="button-group">
                                <a className="btn btn-outline-white"  data-aos="fade-up" data-aos-easing="linear" data-aos-delay="1000">Start Now</a>
                                <a className="btn btn-outline-primary"  data-aos="fade-up" data-aos-easing="linear" data-aos-delay="1100">Learn More</a>
                            </div>
                        </main>
                    </div>
                </div>
                <div className='banner banner1'>
                    <div className="overlay">                        
                        <main className="banner-form">
                            <h1 className="main-head">Cover your page.</h1>
                            <p className="sub-text">Cover is a one-page template for building simple and beautiful home pages.</p>
                            <div className="button-group">
                                <a className="btn btn-outline-white">Start Now</a>
                                <a className="btn btn-outline-primary">Learn More</a>
                            </div>
                        </main>
                    </div>
                </div>
                <div className='banner banner2'>
                    <div className="overlay">                        
                        <main className="banner-form">
                            <h1 className="main-head">Cover your page.</h1>
                            <p className="sub-text">Cover is a one-page template for building simple and beautiful home pages.</p>
                            <div className="button-group">
                                <a className="btn btn-outline-white">Start Now</a>
                                <a className="btn btn-outline-primary">Learn More</a>
                            </div>
                        </main>
                    </div>
                </div>
            </Slider>

        </div>
    )
}
export default Banner;